import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";
import DisponentStore from "./disponentStore";
import TedarikciStore from "./nonKAApproveStore";
import nonKAApproveStore from "./nonKAApproveStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    disponentStore: DisponentStore;
    tedarikciStore: TedarikciStore;
    nonKAApproveStore: nonKAApproveStore;


    // errorStore:ErrorStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    disponentStore: new DisponentStore(),
    tedarikciStore: new TedarikciStore(),
    nonKAApproveStore: new nonKAApproveStore(),
    // errorStore:ErrorStore,
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}