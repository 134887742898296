// import { useEffect } from "react";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";//, useLocation } from "react-router-dom";
import { Container } from "semantic-ui-react";
import { useStore } from "../stores/store";
import NavBar from "./NavBar";

interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export default function PrivateRoute({ component: Component, ...rest }: Props) {
    const { userStore: { isLoggedIn } } = useStore();//, commonStore: { setLastURL } } = useStore();
    // const location = useLocation();

    // useEffect(() => {
    //     if (!isLoggedIn)
    //         setLastURL(location);
    // }, [])

    return (
        <>
            <Container className="MainContainer">
                <div >
                    <NavBar />
                    <Route
                        {...rest}
                        render={(props) => isLoggedIn ? <Component {...props} /> : <Redirect to='/' />}
                    />
                </div>
            </Container>
        </>
    )
}

