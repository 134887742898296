import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { DisponentListResultDto } from "../models/models";

export default class DisponentStore {
    editMode = false;
    loading = false;
    loadingInitial = false;
    disponentListesi: DisponentListResultDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }

    loadDisponentList = async () => {
        this.setLoadingInitial(true);
        try {

            this.disponentListesi = [];
            const result = await agent.Disponentler.list();
            result.forEach(res => {
                this.disponentListesi.push(res);
            })

            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }


    setCalisiyorum = async (calisiyorum: boolean) => {
        this.setLoadingInitial(true);
        try {
            const result = await agent.Disponentler.setCalisiyorum(calisiyorum);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setLoadingInitial(false);
        }
    }

    getCalisiyormu = async () => {
        this.setLoadingInitial(true);
        try {
            const result = await agent.Disponentler.getCalisiyormu();
            return result;
        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setLoadingInitial(false);
        }
    }

}