import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { NonKAApprovmentResultDTO } from "../../app/models/models";
import LoadingComponent from "../../app/layout/LoadingComponent";
import DetailsModal from "./DetailsModal";
import HistroyModal from "./HistroyModal";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import ReactDatePicker from "react-datepicker";
import { tr } from 'date-fns/locale';
import { toast } from 'react-toastify'
import ReadDetailsModal from "./ReadDetailsModal";


export default observer(function NonKAList() {
    const { nonKAApproveStore } = useStore();
    const [nonKAccounts, setNonKAccounts] = useState<NonKAApprovmentResultDTO[]>([]);
    const [filteredNonKAccounts, setFilteredNonKAccounts] = useState<NonKAApprovmentResultDTO[]>([]);

    const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
    const [readDetailsModalOpen, setReadDetailsModalOpen] = useState<boolean>(false);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [accountId, setAccountId] = useState<string>('');
    const [search, setSearch] = useState('');
    const [filterStatus, setFilterStatus] = useState('');

    const [firstListDate, setFirstListDate] = useState<any>('');
    const [lastListDate, setLastListDate] = useState<any>('');
    const [firstApproveDate, setFirstApproveDate] = useState<any>('');
    const [lastApproveDate, setLastApproveDate] = useState<any>('');

    const listPerPage = 5;


    useEffect(() => {
        const fetchData = async () => {
            await nonKAApproveStore.loadNonKAUsers();
            if (nonKAApproveStore.nonKAUsers) {
                setNonKAccounts(JSON.parse(JSON.stringify(nonKAApproveStore.nonKAUsers.nonKAApprovmentList)))
                setFilteredNonKAccounts(JSON.parse(JSON.stringify(nonKAApproveStore.nonKAUsers.nonKAApprovmentList)))

                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(nonKAApproveStore.nonKAUsers.nonKAApprovmentList)).length / listPerPage))
                setFilterStatus('all')
            }
        }
        fetchData()
            .catch(console.error);
    }, [])


    const showHistoryModal = (id: string) => {
        setAccountId(id);
        setHistoryModalOpen(true);
    }
    const showDetailsModal = (id: string) => {
        setAccountId(id);
        setDetailsModalOpen(true);
    }

    const showReadDetailsModal = (id: string) => {
        setAccountId(id);
        setReadDetailsModalOpen(true);
    }

    const closeDetailsModal = (reset: boolean) => {
        setDetailsModalOpen(false);
        if (reset) {
            clearFilters()
        }
    }
    const closeReadDetailsModal = (reset: boolean) => {
        setReadDetailsModalOpen(false);
        if (reset) {
            clearFilters()
        }
    }
    const closeHistoryModal = () => {
        setHistoryModalOpen(false);
    }

    const handleTimePassed = (cDate: any, aDate: any) => {

        let newCDate = new Date(cDate.slice(3, 5) + "." + cDate.slice(0, 2) + "." + cDate.slice(7, 10));
        let newADate = new Date(aDate.slice(3, 5) + "." + aDate.slice(0, 2) + "." + aDate.slice(7, 10));
        newCDate.setHours(parseInt(cDate.slice(11, 18)));
        newADate.setHours(parseInt(aDate.slice(11, 18)));
        newCDate.setMinutes(parseInt(cDate.slice(14, 16)));
        newADate.setMinutes(parseInt(aDate.slice(14, 16)));

        let aDateDay = Math.floor((new Date(newADate).getTime() - new Date(newCDate).getTime()) / (1000 * 60 * 60 * 24));
        let aDateHour = Math.floor((new Date(newADate).getTime() - new Date(newCDate).getTime()) % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        let aDateMinute = Math.floor((new Date(newADate).getTime() - new Date(newCDate).getTime()) % (1000 * 60 * 60 * 24) / (1000 * 60));

        let cDateDay = Math.floor((new Date().getTime() - new Date(newCDate).getTime()) / (1000 * 60 * 60 * 24));
        let cDateHour = Math.floor((new Date().getTime() - new Date(newCDate).getTime()) % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        let cDateMinue = Math.floor((new Date().getTime() - new Date(newCDate).getTime()) % (1000 * 60 * 60 * 24) / (1000 * 60));

        if (cDate != '' && aDate != '') {
            if (aDateDay == 0 && aDateHour == 0) {
                return aDateMinute + ' Dakika'
            }
            else if (aDateDay == 0) {
                return aDateHour + ' Saat'
            }
            else {
                return aDateDay + ' Gün ' + aDateHour + ' Saat'
            }
        }
        else {
            if (cDateDay == 0 && cDateHour == 0) {
                return cDateMinue + ' Dakika'
            }
            else if (cDateDay == 0) {
                return cDateHour + ' Saat'
            }
            else {
                return cDateDay + ' Gün ' + cDateHour + ' Saat'
            }
        }
    }


    const handleSetSearch = (result: any) => {
        setSearch(result)
        setCurrentPage(1);
    }


    const handleFilter = () => {
        if (filterStatus == '' && firstListDate == '' && lastListDate == '' && firstApproveDate == '' && lastApproveDate == '' && search == '') {
            toast.error("En az bir adet arama kriteri giriniz.");
        }
        else {
            var nka = nonKAccounts.filter((item) => {
                return search?.toLowerCase() == '' ? item : item.companyName?.toLowerCase().includes(search);
            }).filter((item) => {
                return filterStatus == 'all' || filterStatus == '' ? item : item.status?.toLowerCase() == filterStatus.toLowerCase();
            }).filter((item) => {
                //let cDate = new Date(item.createdDate.slice(3, 5) + "." + item.createdDate.slice(0, 2) + "." + item.createdDate.slice(6, 10) );
                let cDate = new Date(parseInt(item.createdDate.slice(6, 10)), parseInt(item.createdDate.slice(3, 5)) - 1, parseInt(item.createdDate.slice(0, 2)), parseInt(item.createdDate.slice(11, 13)), parseInt(item.createdDate.slice(14, 16)))
                let mDate = new Date(parseInt(item.approvedDate.slice(6, 10)), parseInt(item.approvedDate.slice(3, 5)) - 1, parseInt(item.approvedDate.slice(0, 2)), parseInt(item.approvedDate.slice(11, 13)), parseInt(item.approvedDate.slice(14, 16)))
                let fLDate = firstListDate == undefined || firstListDate == '' ? new Date(1970, 0, 0) : new Date(firstListDate);
                //let fLDate = firstListDate != undefined ||  firstListDate != '' ? new Date(firstListDate) : new Date(1970,0,0)
                let lLDate = lastListDate == undefined || lastListDate == '' ? new Date(2100, 11, 30) : new Date(lastListDate);
                let fADate = firstApproveDate == undefined || firstApproveDate == '' ? new Date(1970, 0, 0) : new Date(firstApproveDate);
                let lADate = lastApproveDate == undefined || lastApproveDate == '' ? new Date(2100, 11, 30) : new Date(lastApproveDate);

                return cDate >= fLDate && mDate >= fADate && cDate <= lLDate && mDate <= lADate;
            })
            setCurrentPage(1);
            setPageCount(Math.ceil(nka.length / listPerPage))
            setFilteredNonKAccounts(nka);
            if (nka.length == 0) {
                toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            }
        }
    }


    const clearFilters = () => {
        setFilterStatus('all');
        setFirstListDate('');
        setLastListDate('');
        setFirstApproveDate('');
        setLastApproveDate('');
        setSearch('')
        setCurrentPage(1);
        setPageCount(Math.ceil(nonKAccounts.length / listPerPage))
        setFilteredNonKAccounts(nonKAccounts);
    }

    const changePage = async (newPage: number) => {
        await nonKAApproveStore.loadNonKAUsers();
        if (nonKAApproveStore.nonKAUsers) {
            setCurrentPage(newPage);
        }
    }


    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    if (nonKAApproveStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <ModalInnerContainer width={'1000px'} body={<ReadDetailsModal accountId={accountId} handleClose={closeReadDetailsModal} data={''} />} modalTitle='Firma Sahibi Onay Ekranı / Görüntüle' open={readDetailsModalOpen} closeModal={closeReadDetailsModal} />
            <ModalInnerContainer width={'1000px'} body={<DetailsModal accountId={accountId} handleClose={closeDetailsModal} data={''} />} modalTitle='Firma Sahibi Onay Ekranı' open={detailsModalOpen} closeModal={closeDetailsModal} />
            <ModalInnerContainer width={'1000px'} body={<HistroyModal accountId={accountId} handleClose={closeHistoryModal} data={''} />} modalTitle='Tarihçe' open={historyModalOpen} closeModal={closeHistoryModal} />
            <div className="approverFilter" >
                <div className="approverSearch" style={{ width: '180px', marginRight: '15px' }} >
                    <p>Firma Adı</p>

                    <div className="ui category search" style={{ width: '180px' }}>
                        <div className="ui icon input" style={{ width: '180px ' }} >
                            <input className="prompt" style={{ background: '#F2F2F2' }} type="text" placeholder="Firma Adı" onChange={(event) => handleSetSearch(event.target.value)} value={search}></input>
                            <i className="search icon"></i>
                        </div>
                        <div className="results"></div>
                    </div>
                </div>
                <div className="statusDropdown " style={{ width: '180px', marginRight: '15px' }} >
                    <p>İşlem Statüsü</p>


                    <Dropdown style={{
                        width: '180px',
                        backgroundColor: 'rgb(242,242,242)',
                        height: '43px',
                        margin: '0 auto',
                        fontSize: '16px',
                    }}
                        placeholder='İşlem Statüsü'
                        fluid
                        selection
                        options={[
                            { key: 'all', text: "Tümü", value: "all" },
                            { key: 'US_READY', text: "Onaylandı", value: "US_READY" },
                            { key: 'US_DENIED', text: "Reddedildi", value: "US_DENIED" },
                            { key: 'US_NKA_DOCUMENTS_IN_REVIEW', text: "Onay Bekliyor", value: "US_NKA_DOCUMENTS_IN_REVIEW" },
                            { key: 'US_NKA_DOCUMENT_REJECTED', text: "Döküman Bekliyor", value: "US_NKA_DOCUMENT_REJECTED" },
                        ]}
                        value={filterStatus}
                        onChange={(event, { value }) => {
                            if (value != null) {
                                setFilterStatus(value.toString());
                            }
                        }}
                    />

                </div>

                <div className="Startdate" style={{ display: 'flex', flexDirection: 'column' }} >
                    <p>Onaya İlk Düştüğü Tarih</p>
                    <div className="sDates" style={{ display: 'flex' }}>
                        <div className="ui input icon" style={{ marginRight: '10px' }} >
                            <ReactDatePicker
                                autoComplete='off'
                                locale={tr}
                                dateFormat={'dd.MM.yyyy'}
                                name='StartDate'
                                placeholderText='gg.AA.YYYY'
                                selected={firstListDate}
                                onChange={setFirstListDate}
                                className="dateArea"
                            />
                            <i className="calendar icon" ></i>
                        </div>

                        <div className="ui input icon" >
                            <ReactDatePicker
                                autoComplete='off'
                                locale={tr}
                                dateFormat={'dd.MM.yyyy'}
                                name='StartDate'
                                placeholderText='gg.AA.YYYY'
                                selected={lastListDate}
                                onChange={setLastListDate}
                                className="dateArea"
                            />
                            <i className="calendar icon" ></i>
                        </div>

                    </div>
                </div>
                <div className="Enddate" style={{ display: 'flex', flexDirection: 'column' }} >
                    <p>İşlem Tamamlanma Tarihi</p>
                    <div className="eDates" style={{ display: 'flex' }}>
                        <div className="ui input icon" style={{ marginRight: '10px' }}>
                            <ReactDatePicker
                                autoComplete='off'
                                locale={tr}
                                dateFormat={'dd.MM.yyyy'}
                                name='StartDate'
                                placeholderText='gg.AA.YYYY'
                                selected={firstApproveDate}
                                onChange={setFirstApproveDate}
                                className="dateArea"
                            />
                            <i className="calendar icon" ></i>
                        </div>

                        <div className="ui input icon" style={{ zIndex: '2' }} >
                            <ReactDatePicker
                                autoComplete='off'
                                locale={tr}
                                dateFormat={'dd.MM.yyyy'}
                                name='StartDate'
                                placeholderText='gg.AA.YYYY'
                                selected={lastApproveDate}
                                onChange={setLastApproveDate}
                                className="dateArea"
                            />
                            <i className="calendar icon" ></i>
                        </div>
                    </div>
                </div>
                <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                    <input className='button  confirm-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                    <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                </div>
            </div>

            <div className="approverList" id="approverList" >
                {filteredNonKAccounts.length == 0 ?
                    <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                        <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                        <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />
                    </div>

                    : <table style={{ background: '#32C5FF' }}>
                        <thead className="ApproverTableHeader">
                            <tr>
                                <th >Firma Sahibi İsim</th>
                                <th style={{ width: '10px' }}>Firma Sahibi Soyisim</th>
                                <th >Firma Adı</th>
                                <th >Firma VKN</th>
                                <th >Müşteri TCKN</th>
                                <th >Onaya İlk Düştüğü Tarih</th>
                                <th style={{ width: '50%' }}>İşlem Statüsü</th>
                                <th >İşlem Tamamlanma Tarihi</th>
                                <th style={{ width: '100%' }}>Geçen Süre</th>
                                <th className="stickyColumn2">İncele/Görüntüle</th>
                                <th className="stickyColumn">Tarihçe</th>
                            </tr>
                        </thead>
                        <tbody >
                            {filteredNonKAccounts.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                .map((nkUser) => (
                                    <tr key={nkUser.id} >
                                        <td style={{ padding: '0 !important' }}>{nkUser.name}</td>
                                        <td>{nkUser.surname}</td>
                                        <td>{nkUser.companyName}</td>
                                        <td>{nkUser.vkn}</td>
                                        <td>{nkUser.tckn}</td>
                                        <td>{nkUser.createdDate}</td>
                                        {(nkUser.status == "US_NKA_DOCUMENTS_IN_REVIEW") ? <td>Onay Bekliyor</td>
                                            : (nkUser.status == "US_DENIED") ? <td>Reddedildi</td>
                                                : (nkUser.status == "US_NKA_DOCUMENT_REJECTED") ? <td>Döküman Bekleniyor</td>
                                                    : (nkUser.status == "US_READY") ? <td>Onaylandı</td> : <td>{nkUser.status}</td>}
                                        <td>{nkUser.approvedDate != '' ? nkUser.approvedDate : "İşlem Tamamlanmadı"}</td>
                                        <td>{handleTimePassed(nkUser.createdDate, nkUser.approvedDate)}</td>

                                        {(nkUser.status == "US_NKA_DOCUMENTS_IN_REVIEW") ?
                                            <td className="stickyColumn2"><a style={{
                                                cursor: "pointer",
                                                color: "auto",
                                            }} onClick={() => { showDetailsModal(nkUser.id) }} >
                                                İncele
                                            </a></td>
                                            : <td className="stickyColumn2"><a style={{
                                                cursor: "pointer",
                                                color: "auto",
                                            }} onClick={() => { showReadDetailsModal(nkUser.id) }} >
                                                Görüntüle
                                            </a></td>
                                        }
                                        <td className="stickyColumn">
                                            <a style={{ cursor: 'pointer' }} onClick={() => { showHistoryModal(nkUser.id); }}>Tarihçe </a></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }
            </div>,
            {pageCount < 1 ?
                <></>
                :
                <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }}   >{createElements()}</div>
            }

        </>
    )
})