import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, useLocation, useParams } from 'react-router-dom';
import { Button, Container, Header, Icon, Segment } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
// import { useStore } from "../stores/store";

export default function ImageView() {
    const { nonKAApproveStore, userStore: { isLoggedIn } } = useStore();
    const { img } = useParams<{ img: string }>();
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)

    const [image, setImage] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                // imageParam
                //img.get("type")}
                const img = queryParameters.get("img");
                if (!img)
                    return;
                const response = await nonKAApproveStore.getImage(img);
                if (!response)
                    return;
                const blob = new Blob([response], { type: 'image/jpeg' });
                // Blob nesnesini kullanarak URL oluşturma
                setImage(URL.createObjectURL(blob));
                // const imagev = document.querySelector("img");
                // if (imagev != null)
                //     imagev.src = URL.createObjectURL(blob);
                // setImg(response);
                // const imageUrl = URL.createObjectURL(response);
            } catch (error) {
                console.log(error);
                return null;
            }
            finally {
                setLoading(false);
            }
        }
        fetchData()
            .catch(console.error);
    }, [])

    if (loading) return <div className='modalLoader'><LoadingComponent content='Yükleniyor...' /></div>

    return (
        <div className='image-container'>
            {isLoggedIn ?
                image ? <>
                    <img src={image} alt="Resim" />
                </> : <>Resim bulunamadı</>
                : <Redirect to='/' />}
        </div>
    )
}