import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Button, Confirm, Dropdown, Form, Header, Icon, Image, Label, Menu, Modal, Table } from 'semantic-ui-react';
import MoonLoader from "react-spinners/MoonLoader";
import styled from "styled-components";
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
import InputErrorMessage from '../components/inputErrorMessage';
import { DisponentListResultDto, TedarikciDetailDto } from '../../app/models/models';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import * as XLSX from 'xlsx'
import historyModal from '../nonKAList/DetailsModal';
import NonKAList from '../nonKAList/NonKAList';

const LoadingWrapper = styled.div`
  margin-top:50px;
  margin-bot:50px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default observer(function Dashboard() {

    return (
        <>
            <div style={{ width: '100%', display:'flex', flexDirection:'column'}}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background:'#32C5FF' }}>
                    <span className='BannerTitle'>ANA SAYFA</span>
                </div>
                <div className='ApproverBodyContainer' style={{ width: '100%' }}>



                    <NonKAList />
                </div>
                <div className='FooterContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px' }}>
                </div>

                <script>

                </script>
            </div >
        </>
    )
})