import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { TextArea } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import { NonKAApprovmentDetailDTO } from '../../app/models/models'
import HistroyModal from './HistroyModal'
import ModalInnerContainer from '../../app/common/modals/ModalInnerContainer'
import { toast } from 'react-toastify'
import LoadingComponent from '../../app/layout/LoadingComponent'

interface Props {
  accountId: string;
  handleClose: any;
  data: any;
}

export default observer(function ReadDetailsModal(props: Props) {
  const { nonKAApproveStore } = useStore();

  const [circularOfSignature, setCircularOfSignature] = useState<string>('');
  const [circularOfSignature2, setCircularOfSignature2] = useState<string>('');
  const [circularOfSignature3, setCircularOfSignature3] = useState<string>('');
  const [identityCardBack, setIdentityCardBack] = useState<string>('');
  const [identityCardFront, setIdentityCardFront] = useState<string>('');
  const [taxCertificate, setTaxCertificate] = useState<string>('');

  const [accountId, setaccountId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyVkn, setCompanyVkn] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [companyTckn, setCompanyTckn] = useState<string>('');
  const [description, setDescription] = useState<string>('');


  const [idendityCardVerifiedCbx, setIdendityCardVerifiedCbx] = useState(false);
  const [taxCertificateVerifiedCbx, setTaxCertificateVerifiedCbx] = useState(false);
  const [circularOfSignatureVerifiedCbx, setCircularOfSignatureVerifiedCbx] = useState(false);


  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

  const [baseURL, setBaseURL] = useState<string>('');


  const showHistoryModal = (id: string) => {
    setHistoryModalOpen(true);
  }

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
  }

  function CloseSubmit() {
    props.handleClose(false);
  }






  useEffect(() => {
    const currentURL = window.location.href;
    const pathname = window.location.pathname;
    const basePath = currentURL.replace(pathname, '');
    setBaseURL(basePath);
    const fetchData = async () => {
      const data = await nonKAApproveStore.getNonKAApprovmentDetail(props.accountId);
      if (!data) {
        toast.error("Kayıt bulunamadı");
        props.handleClose(false);
        return;
      }
      var mydata = JSON.parse(JSON.stringify(data)).data;
      setName(data.name)
      setSurname(data.surname)
      setCompanyName(data.companyName)
      setCompanyTckn(data.tckn)
      setCompanyVkn(data.vkn)
      setPhoneNumber(data.phoneNumber)
      setDescription(data.description)

      setIdentityCardFront(data.identityCardFront);
      setIdentityCardBack(data.identityCardBack);
      setTaxCertificate(data.taxCertificate);
      setCircularOfSignature(data.circularOfSignature);
      setCircularOfSignature2(data.circularOfSignature2);
      setCircularOfSignature3(data.circularOfSignature3);

      setCircularOfSignatureVerifiedCbx(!data.circularOfSignatureVerified)
      setIdendityCardVerifiedCbx(!data.identityCardVerified)
      setTaxCertificateVerifiedCbx(!data.taxCertificateVerified)
    }
    fetchData()
      .catch(console.error);
  }, [])

  if (nonKAApproveStore.modalLoadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <ModalInnerContainer width={'1000px'} body={<HistroyModal accountId={props.accountId} handleClose={closeHistoryModal} data={''} />} modalTitle='Tarihçe' open={historyModalOpen} closeModal={closeHistoryModal} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='formTable' style={{ width: '50%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Firma Bilgileri</div>
          <hr className='modalLine'></hr>
          <table style={{ padding: "0" }}>
            <tbody>
              <tr>
                <td>Firma Sahibi İsim</td>
                <td>
                  <input type='text'                    
                    style={{ width: '200px' }} disabled onChange={(event) => { setName(event.target.value); }} value={(name == null) ? '' : name} />
                </td>
              </tr>
              <tr>
                <td>Firma Sahibi Soyisim</td>
                <td>
                  <input type='text'                   
                    style={{ width: '200px' }} disabled onChange={(event) => { setSurname(event.target.value); }} value={(surname == null) ? '' : surname} />
                </td>
              </tr>
              <tr>
                <td>Firma Sahibi Cep No</td>
                <td>
                  <input type='text' style={{ width: '200px' }} disabled onChange={(event) => { setPhoneNumber(event.target.value); }} value={(phoneNumber == null) ? '' : phoneNumber} />
                </td>
              </tr>
              <tr>
                <td>Firma Adı</td>
                <td>
                  <input type='text'                   
                    style={{ width: '200px' }} disabled onChange={(event) => { setCompanyName(event.target.value); }} value={(companyName == null) ? '' : companyName} />
                </td>
              </tr>
              <tr>
                <td>Firma VKN</td>
                <td>
                  <input type='text'
                    style={{ width: '200px' }} disabled onChange={(event) => { setCompanyVkn(event.target.value); }} value={(companyVkn == null) ? '' : companyVkn} />
                </td>
              </tr>
              <tr>
                <td>Firma Sahibi TCKN</td>
                <td>
                  <input type='text'
                    style={{ width: '200px' }} disabled onChange={(event) => { setCompanyTckn(event.target.value); }} value={(companyTckn == null) ? '' : companyTckn} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='formTable' style={{ width: '45%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Yüklenen Belgeler</div>
          <hr className='modalLine'></hr>
          <div className="uploadedItems" style={{ display: "flex", flexDirection: "column" }}>
            <a target="_blank" href={`${baseURL}/image?img=${identityCardFront}`} style={{ padding: "10px" }}>T.C. Kimlik Belgesi Önden Resim</a>
            <a target="_blank" href={`${baseURL}/image?img=${identityCardBack}`} style={{ padding: "10px" }}>T.C. Kimlik Belgesi Arkadan Resim </a>
            <a target="_blank" href={`${baseURL}/image?img=${taxCertificate}`} style={{ padding: "10px" }}>Vergi Levhası</a>
            <a target="_blank" href={`${baseURL}/image?img=${circularOfSignature}`} style={{ padding: "10px" }}>İmza Sirküleri 1</a>
            <a target="_blank" href={`${baseURL}/image?img=${circularOfSignature2}`} style={{ padding: "10px" }}>İmza Sirküleri 2</a>
            <a target="_blank" href={`${baseURL}/image?img=${circularOfSignature3}`} style={{ padding: "10px" }}>İmza Sirküleri 3</a>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='formTable' style={{ width: '50%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Geri Gönderme / Reddetme Nedeni</div>
          <hr className='modalLine'></hr>
          <table style={{ padding: "10px" }}>
            <tbody>
              <tr>
                <td>T.C. Kimlik belgesi Eksik / Hatalı</td>
                <td>
                  <label className="switch">
                    <input disabled style={{ cursor: 'default !important' }} checked={idendityCardVerifiedCbx} onChange={(event) => { setIdendityCardVerifiedCbx(event.target.checked); }} name="idendityCardCbx" type="checkbox" />
                    <span style={{ cursor: 'default !important' }} className="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Vergi Levhası Eksik / Hatalı</td>
                <td>
                  <label className="switch">
                    <input disabled checked={taxCertificateVerifiedCbx} onChange={(event) => { setTaxCertificateVerifiedCbx(event.target.checked); }} name="taxCertificateVerifiedCbx" type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>İmza sirküleri Eksik / Hatalı</td>
                <td>
                  <label className="switch">
                    <input disabled checked={circularOfSignatureVerifiedCbx} onChange={(event) => { setCircularOfSignatureVerifiedCbx(event.target.checked); }} name="circularOfSignatureVerifiedCbx" type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='formTable' style={{ width: '45%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Açıklama</div>
          <hr className='modalLine' ></hr>
          <div style={{ paddingBottom: '10px' }}></div>
          <TextArea disabled className="description" onChange={(event) => { setDescription(event.target.value) }} style={{ width: "100%" }} value={description} />
        </div>
      </div>
      <div className="detailsPageContainer" style={{ padding: "0 0 0 20px" }}>
        <div style={{ marginTop: '30px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit() }} type='submit' value='Kapat' />
              <input className='button mr20 ui buton' onClick={() => { showHistoryModal(accountId); }} type='submit' value='Tarihçe' />
            </div>
            <div style={{ display: 'flex' }}>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})