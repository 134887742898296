import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { DisponentListResultDto, ParametreDetailDto, ParcaNoDetailDto, ParcaNoGuncelleDto, SiparisArsivResultDto, siparisEdit, SiparisListRequestDto, SiparisResultDto, SiparisStatuGuncelleDto, TedarikciDetailDto, TedarikciFileResultDto, TedarikciListResultDto, yeniParcaNoGuncelleDto, SpicsNoDto, SorumlularDto, NonKAApprovmentResultDTO, NonKAApprovmentHistoryDTO, NonKAApprovmentDetailDTO, NonKaAproveDTO, NonKaDenyDTO, NonKaSendBackDTO, NonKeyResponseDTO } from '../models/models';
import { PaginatedResult } from '../models/pagination';
import { ServerError } from '../models/serverError';
import { User, UserFormValues, UserSearchListItem } from '../models/user';
import { store } from '../stores/store';
import { HttpRequest } from '@microsoft/signalr';

// const sleep = (delay: number) => {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay)
//     })
// }

axios.defaults.baseURL = process.env.REACT_APP_API_URL + 'api';

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`
    config.withCredentials=true;
    return config;
})

axios.interceptors.response.use(async response => {
    // await sleep(2000);
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }
    return response;
}, (error: AxiosError) => {
    if (error.response) {
        const { data, status, config } = error.response!;
        switch (status) {
            case 400:
                if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                    history.push('/');
                    toast.error(data);
                }
                if (data.errors) {
                    const modalStateErrors = [];
                    for (const key in data.errors) {
                        if (data.errors[key]) {
                            modalStateErrors.push(data.errors[key])
                        }
                    }
                    toast.error(modalStateErrors.flat().toString());
                    throw modalStateErrors.flat();
                } else {
                    toast.error(data);
                }
                break;
            case 401:
                toast.error('Yetkisiz giriş');
                break;
            case 404:
                history.push('/not-found');
                break;
            case 500:
                store.commonStore.setServerError(data);
                history.push('/server-error');
                break;
        }
    }
    else {
        let detail: string = '';


        if (error.message.trim().toUpperCase() === "NETWORK ERROR") {
            detail = 'Ağ bağlantısında problem oluştu!'
        }
        else {
            detail = error.stack ? error.stack : '';

        }

        const errM: ServerError = {
            statusCode: 99,
            message: error.message,
            details: detail
        };

        store.commonStore.setServerError(errM);
        history.push('/server-error');
        toast.error(error.message);
    }
    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const responseStatus = <T>(response: AxiosResponse<T>) => response.status;
// const isSuccess = <T>(response: AxiosResponse<T>) => response.status == 200 ? true : false;

const requests = {

    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    postwStatus: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseStatus),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
    // fetch: <T>(url: string) => axios.get<T>(url).then(responseBody),
}


const Disponentler = {
    list: () => requests.get<DisponentListResultDto[]>('/disponentler/List/'),
    setCalisiyorum: (calisiyorum: boolean) => requests.post<void>(`/disponentler/SetCalisiyorum/${calisiyorum}`, ''),
    getCalisiyormu: () => requests.get<boolean>('/disponentler/getcalisiyormu/'),
}

const Tedarikciler = {
    get: (tedarikciNo: number) => requests.get<TedarikciDetailDto>(`/tedarikciler/${tedarikciNo}`),
    createUpdate: (td: TedarikciDetailDto) => requests.post<void>('/tedarikciler/', td),
    delete: (tedarikciNo: number) => requests.post<void>(`/tedarikciler/delete/${tedarikciNo}`, ''),
    list: () => requests.get<TedarikciListResultDto[]>('/tedarikciler/List/'),
    createUpdateWithFile: (tedarikciFile: FormData) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post<TedarikciFileResultDto>('/tedarikciler/CreateUpdateWithFile', tedarikciFile, config).then((response) => {
            return response.data;
        });
    },
}

const NonKAccounts = {

    list: () => requests.get<NonKAApprovmentResultDTO[]>('/NonKeyAccount/List'),
    history: (accountId: string) => requests.get<NonKAApprovmentHistoryDTO[]>(`/NonKeyAccount/History/${accountId}`),
    detail: (accountId: string) => requests.get<NonKAApprovmentDetailDTO>(`/NonKeyAccount/Detail/${accountId}`),
    // history:  (accountId : string) => requests.get<NonKAApprovmentHistoryDTO>(`/NonKeyAccount/History/${accountId}`),
    approve: (approve: NonKaAproveDTO) => requests.postwStatus<void>('/NonKeyAccount/Approve/', approve),
    deny: (deny: NonKaDenyDTO) => requests.postwStatus<void>('/NonKeyAccount/Deny/', deny),
    sendback: (sendback: NonKaSendBackDTO) => requests.postwStatus<void>('/NonKeyAccount/SendBack/', sendback),
    // getImage: (fileName: string) => requests.get<Blob>(`/Blob/${fileName}`),
    getImage: (fileName: string) => axios.get(`${axios.defaults.baseURL}/Blob?fileName=${fileName}`, { responseType: 'blob' }).then((response) => {
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        return blob;
    }),
}



const Account = {
    current: () => requests.get<User>('/Account'),
    login: (user: UserFormValues) => requests.post<User>('/Account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/Account/register', user),
    refreshToken: () => requests.post<User>("/Account/refreshToken", {}),

}

const Users = {
    get: (username: string) => requests.get<UserSearchListItem[]>(`/users/${username}`),
}

// const Blob = {
//     get: () => axios.get(endpointUrl, { responseType: 'blob' })
//         .then((response) => {
//             // Resmi almak için blob nesnesini kullanın
//             const blob = new Blob([response.data], { type: 'image/jpeg' });

//             // Blob'dan URL oluşturun ve setState ile resmi ayarlayın
//             const imageUrl = URL.createObjectURL(blob);
//             setImage(imageUrl);
//         })
//         .catch((error) => {
//             console.error('Resim alınamadı:', error);
//         });
// }

const agent = {
    Account,
    Users,
    Disponentler,
    Tedarikciler,
    NonKAccounts,
}

export default agent;