import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
// import React from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu, Dropdown } from 'semantic-ui-react';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';

export default observer(function NavBar() {
    const { userStore: { user, logout, isLoggedIn }, disponentStore } = useStore();
    const [calisiyorum, setCalisiyorum] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    if (isSubmitting) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <div className='navbarContainer'>
            <div style={{ display: 'flex' }}>
                <NavLink to="/" activeStyle={{ fontWeight: "bold", color: "red" }} style={{ display: 'flex' }}>
                    <img src={`/assets/DAIMLER_LOGO.png`} alt='logo' width='200px'></img>
                </NavLink>


                <div style={{ marginLeft: '20px' }}>
                    {/* {<Menu.Item as={NavLink} className='navBarLink' to='/siparisler' content='EDI Siparişleri' name='Siparis Dashboard' />} */}
                    {<Menu.Item as={NavLink} className='navBarLink' to='/dashboard' content='Ana Sayfa' name='Ana Sayfa' />}
                    {/* {<Menu.Item as={NavLink} className='navBarLink' to='/ParametreSecim' content='Parametre Seçim' name='ParametreSecim' />} */}
                    {/* {<Menu.Item as={NavLink} className='navBarLink' to='/SpicsMusteri' content='Spics Müşteri' name='SpicsMusteri' />} */}
                    {/* {<Menu.Item as={NavLink} className='navBarLink' to='/Sorumlular' content='Sorumlular' name='Sorumlular' />} */}
                </div>

            </div>

            <div className='userNameContainer'>
                <Dropdown className='userNameLink' pointing='top left' text={user?.username}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={logout} text='Çıkış Yap' icon='power' />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
})