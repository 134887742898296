import { makeAutoObservable } from "mobx";
import { NonKAApprovmentDetailDTO, NonKAApprovmentHistoryDTO, NonKAApprovmentHistoryResultDTO, NonKAApprovmentListResultDto, NonKAApprovmentResultDTO, NonKaAproveDTO, NonKaDenyDTO, NonKaSendBackDTO } from "../models/models";
import agent from "../api/agent";

export default class nonKAApproveStore {

    nonKAUsers: NonKAApprovmentListResultDto  | undefined = undefined;
    nonKAccountHistory: NonKAApprovmentHistoryResultDTO | undefined = undefined;
    nonKAUserDetails: NonKAApprovmentDetailDTO[] = [];

    loadingInitial = false;
    modalLoadingInitial = false;
    historyLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }
    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }
    setHistoryLoadingInitial = (val: boolean) => {
        this.historyLoadingInitial = val;
    }


    loadNonKAUsers = async () => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.NonKAccounts.list();
            if (result) {
                this.nonKAUsers = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getNonKAApprovmentDetail = async (id: string): Promise<NonKAApprovmentDetailDTO | null> => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.NonKAccounts.detail(id);
            return result;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getNonKAApprovmentHistory = async (id: string) => {
        this.setHistoryLoadingInitial(true);
        try {
            const result = await agent.NonKAccounts.history(id);
            if (result) {
                this.nonKAccountHistory = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setHistoryLoadingInitial(false);
        }
    }

    approveNonKeyAccount = async (req: NonKaAproveDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.NonKAccounts.approve(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    denyNonKeyAccount = async (req: NonKaDenyDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.NonKAccounts.deny(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    sendBackNonKeyAccount = async (req: NonKaSendBackDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.NonKAccounts.sendback(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getImage = async (req: string) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.NonKAccounts.getImage(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }








}